import React from 'react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';
import { StaticImage } from 'gatsby-plugin-image';
import Layout from '@/components/Layout';
import SEO from '@/components/Seo';
import { Container, PageContainer, PageHeader } from '../components/elements';
import { A, P, H2, H3, Hr, TableOfContents } from './components';

const ContentContainer = styled.aside`
  display: flex;
  margin: 3rem 0;
`;
const LeftSideContainer = styled.aside`
  display: none;
  width: 350px;
  padding-right: 15px;
  margin-top: 15px;
  border-right: 1px solid ${(props) => props.theme.border.gray};
  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    display: block;
  }
`;
const RightSideContainer = styled.section`
  width: 100%;
  margin-left: 30px;
`;

function MdxLayoutLegal({ pageContext }) {
  const {
    headings,
    body,
    contentId,
    title,
    subtitle,
    lang,
    browserTitle,
    browserDescription,
  } = pageContext;

  const shortcodes = { Link, a: A, p: P, h2: H2, h3: H3, hr: Hr };

  return (
    <Layout>
      <SEO lang={lang} title={browserTitle} description={browserDescription} />

      <PageContainer>
        <PageHeader
          title={title}
          subtitle={subtitle}
          bgImage={
            <StaticImage
              src="../assets/images/about-header-bg.png"
              alt=""
              quality={100}
              layout={`fixed`}
              height={300}
            />
          }
          bgColor={`primary`}
        />
        <Container>
          <ContentContainer>
            <LeftSideContainer>
              <TableOfContents headings={headings} contentId={contentId} />
            </LeftSideContainer>
            <RightSideContainer>
              <MDXProvider components={shortcodes}>
                <MDXRenderer>{body}</MDXRenderer>
              </MDXProvider>
            </RightSideContainer>
          </ContentContainer>
        </Container>
      </PageContainer>
    </Layout>
  );
}

export default MdxLayoutLegal;
